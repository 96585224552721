<template>
  <el-row class="table">
    <span class="title">消费统计</span>
    <!-- time：{{time}} -->
    <el-row class="row-bg">
      <el-date-picker
        class="tb-picker"
        v-model="time"
        type="daterange"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期不含当天">
      </el-date-picker>

      <!-- <el-date-picker
        v-model="firstdate"
        :picker-options="pickerOptions0"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        style="width:300px"
        ></el-date-picker> -->
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    </el-row>

    <template v-if="dataList.classData.length !== 0">
      <el-row class="mb-20">
        <p>总计：<span class="class-total" v-text="costTotal()"></span>课节数：<span class="class-total" v-text="lesson_total"></span></p>
      </el-row>
      <el-row v-for="(item, index) of dataList.classData" :key="index">
        <!-- {{item}} -->
        <el-row class="mb-10 clearfix">
          <span class="fl class-name">{{item[0].className}}</span>
          <span class="fr mr-10">消费金额：<span class="class-gold" v-text="sum(item)"></span></span>
        </el-row>
        <el-table :data="item" border fit highlight-current-row class="tb-list mb-20" style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);">
          <el-table-column align="left" label="课节名称">
            <template slot-scope="scope">
              <span>{{ scope.row.liveName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="开始时间" prop="start_time"></el-table-column>
          <el-table-column align="center" label="授课时长/预计时长">
            <template slot-scope="scope">
              <span v-text="attendTime(scope.row.teach_time)"></span>
              <span v-text="planTime(scope.row.start_time, scope.row.end_time)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="老师总时长">
            <template slot-scope="scope">
              <span v-text="attendHourTime(scope.row.teach_time)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="学员总时长">
            <template slot-scope="scope">
              <span v-text="attendHourTime(scope.row.student_time)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="助教总时长">
            <template slot-scope="scope">
              <span v-text="attendHourTime(scope.row.admin_time)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="班型">
            <template slot-scope="scope">
              <span v-text="getMaxUsersText(scope.row.max_users)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="结算金额" prop="amount"></el-table-column>
        </el-table>
      </el-row>
    </template>

    <template v-else class="noData">
      暂无数据
    </template>

  </el-row>
</template>

<script>
import { getLiveCostByDate } from '@/api/statistics'
import getMaxUsersText from '@/mixins/getStateText'

export default {
  mixins: [getMaxUsersText],
  data () {
    return {
      time: null,
    //   firstdate: null,
    //   pickerOptions0: {
    //     disabledDate(time) {
    //      return time.getTime() > Date.now() - 8.64e6
    //     }
    //   },
      dataList: {
        classData: [
          // [
          //   {
          //     id: 1,
          //     className: '123',
          //     kejieName: '可达鸭D1班',
          //     startTime: '2020/03/15',
          //     jine: '120.8'
          //   },
          // ],
          // [
          //   {
          //     id: 2,
          //     className: '123',
          //     kejieName: '可达鸭D3班',
          //     startTime: '2020/03/15',
          //     jine: '180.8'
          //   }
          // ]
        ]
      },
      classList: [],
      lesson_total: 0,
      cost_total: 0
    }
  },
  created () {
    // this.getLiveCostByDate()
    this.attendHourTime(3600)
  },
  methods: {
    costTotal () {
      return this.cost_total.toFixed(2)
    },
    async getLiveCostByDate () {
      this.lesson_total = 0,
      this.cost_total = 0
      this.dataList = {
        classData: []
      }
      const res = await getLiveCostByDate({
        startDate: this.time[0],
        endDate: this.time[1]
      })
      this.lesson_total = res.body.length
      const classList = []
      res.body.forEach((item, index) => {
        classList.push(item.class_id)
      })
      this.classList = this.unique(classList)
      // console.log('res', res.body)
      res.body.forEach((ele, idx) => {
        this.cost_total += Number(ele.amount)
        this.classList.forEach((item, index) => {
          if (ele.class_id === item) {
            // console.log(item, index, ele, idx)
            if (!this.dataList.classData[index]) {
              this.dataList.classData[index] = []
              this.dataList.classData[index].push(ele)
            } else {
              this.dataList.classData[index].push(ele)
            }
          }
        })

      })
      this.$set(this.dataList)
    },

    search () {
      if (!this.time) {
        window.$msg('请选择日期', 2)
      } else {
        this.getLiveCostByDate()
        this.$forceUpdate()
      }
    },

    reset () {
      this.time = null
    },

    unique (arr) {
      return Array.from(new Set(arr))
    },

    sum (arr) {
      let total = 0
      arr.forEach((item, index) => {
        total+= item.amount
      })
      return total.toFixed(2)
    },

    planTime (start_time, end_time) {
      let startTime = new Date(start_time).getTime()
      let endTime = new Date(end_time).getTime()
      // console.log('')
      let difference = (endTime - startTime) / 1000 / 60
      return '/' + difference + '分钟'
    },

    attendTime (teach_time) {
      let attendTime = teach_time / 60
      let attendTime_str = attendTime.toString()
      return attendTime.toString().indexOf('.') !== -1 ? attendTime.toFixed(0) + '分钟' : attendTime + '分钟'
      // return attendTime.toString().indexOf('.') !== -1 ? parseInt(attendTime_str.substring(0,attendTime_str.indexOf('.'))+1) + '分钟' : attendTime + '分钟'
    },

    attendHourTime (time) {
      let getTime = time / 60
      if (time % 3600 === 0) {
        getTime = time / 3600 + '小时'
        // console.log('整除', getTime)
      } else if (getTime > 60) {
        let attendTime = (getTime / 60).toString().split('.')
        // console.log('attendTime', attendTime)
        getTime = attendTime[0] + '小时' + (('.' + attendTime[1])* 60).toFixed(0) + '分钟'
        // console.log('getTime', getTime)
      } else {
        getTime = getTime.toFixed(0) + '分钟'
        // console.log('分钟', getTime)
      }
      return getTime
    },

    beforeTime (time) {
      let splitTime = []
      splitTime = time.split('-')
      splitTime[2] = splitTime[2] -1
      return splitTime[0] + '-' + splitTime[1] + '-' + splitTime[2]
    }
  }
}
</script>

<style lang="scss" scope>
@import "../../style/table.scss";
.class-name {
  font-size: 18px;
}
.class-gold {
  color: #FD3F68;
  font-weight: 700;
}
.class-total {
  display: inline-block;
  margin-right: 15px;
  color: #1896FF;
  font-weight: 700;
}
</style>
