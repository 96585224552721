export default {
  methods: {
    getStateNum (status) {
      switch (status) {
        case '1' :
          return '已启用'
        case '3' :
          return '已禁用'
        case '2' :
          return '已删除'
        default :
          return '未知'
      }
    },
    pythonText (state) {
      switch (state) {
        case true :
          return '正确'
        case false :
          return '错误'
        case null :
          return '未批阅'
        default :
          return '系统未知状态'
      }
    },
    // 获取语言
    getLanText (state) {
      switch (state) {
        case 0: {
          return 'C'
        }
        case 1: {
          return 'C++'
        }
        case 2: {
          return 'Pascal'
        }
        case 4: {
          return 'Ruby'
        }
        case 5: {
          return 'Bash'
        }
        case 6: {
          return 'Python'
        }
        case 7: {
          return 'php'
        }
        case 8: {
          return 'perl'
        }
        case 9: {
          return 'c#'
        }
        case 10: {
          return 'objectiveC'
        }
        case 11: {
          return 'freeBasic'
        }
        case 12: {
          return 'scheme'
        }
        case 13: {
          return 'Clang'
        }
        case 14: {
          return 'Clang++'
        }
        case 15: {
          return 'Lua'
        }
        case 16: {
          return 'JavaScript'
        }
        case 17: {
          return 'Go'
        }
      }
    },
    getSubType (state) {
      switch (state) {
        case 0:
          return '等待'
        case 1:
          return '等待重判'
        case 2:
          return '编译中'
        case 3:
          return '运行并评判'
        case 4:
          return '正确'
        case 5:
          return '格式错误'
        case 6:
          return '答案错误'
        case 7:
          return '时间超限'
        case 8:
          return '内存超限'
        case 9:
          return '输出超限'
        case 10:
          return '运行错误'
        case 11:
          return '编译错误'
        case 12:
          return '编译成功'
        case 13:
          return '运行完成'
        default:
          return '未知状态'
      }
    },

    // 班型
    getMaxUsersText (state) {
      switch (state) {
        case 1:
          return '1v1'
        case 2:
          return '1v2'
        case 3:
          return '1v3'
        case 4:
          return '1v4'
        case 5:
          return '1v5'
        case 6:
          return '1v6'
        case 7:
          return '1v7'
        case 8:
          return '1v8'
        case 9:
          return '1v9'
        case 10:
          return '1v10'
        case 11:
          return '1v11'
        case 12:
          return '1v12'
        default:
          return ''
      }
    },

    getNewsText (state) {
      switch (state) {
        case 1:
          return '待发布'
        case 2:
          return '发布'
        default:
          return '系统未知状态'
      }
    },
    getGroupText (state) {
      switch (state) {
        case '1':
          return '启用'
        case '2':
          return '禁用'
        default:
          return '系统未知状态'
      }
    }
  }
}
