import $axios from '@/utils/request'

// 同步排课数据
export function replaceBat (data) {
  const url = '/scheduleXgj/replaceBat'
  return $axios.fPost(url, data)
}

// 统计教师课时
export function statTeacherLesson (data) {
  const url = '/schedule/statTeacherLesson'
  return $axios.fPost(url, data)
}

// 获取教师课酬统计列表
export function getPayStat (data) {
  const url = '/schedule/listTeacherLessonPayStat'
  return $axios.fPost(url, data)
}

// 删除教师课时统计日志
export function delLessonStat (data) {
  const url = '/schedule/delTeacherLessonStat'
  return $axios.fPost(url, data)
}

// 获取教师课时统计日志列表
export function getTeacherLessonStat (data) {
  const url = '/schedule/listTeacherLessonStat'
  return $axios.fPost(url, data)
}

// 查询全部课程
export function getCourseAll (data) {
  const url = '/course/getCourseAll'
  return $axios.fPost(url, data)
}

// 按照学生和课程统计课时
export function getStudentCourseHour (data) {
  const url = '/student/course/getStudentCourseHour'
  return $axios.fPost(url, data)
}

// 获取教师课时详情列表
// export function getListTeacherLesson (data) {
//   const url = '/schedule/listTeacherLesson'
//   return $axios.fPost(url, data)
// }

// 直播间统计消费明细
export function getLiveCostByDate (data) {
  const url = '/class/live/getLiveCostByDate'
  return $axios.fGet(url, data)
}

// 我的异常事件列表
export function getExceptionEventList (data) {
  const url = '/event/exceptionEventList'
  return $axios.fPost(url, data)
}

// 全部异常事件列表
export function getExceptionEventListAll (data) {
  const url = '/event/exceptionEventListAll'
  return $axios.fPost(url, data)
}

// 课时明细
export function getBySudentCourse (data) {
  const url = '/lesson/sale/lessonSaleInfo/getBySudentCourse'
  return $axios.fPost(url, data)
}

// 手动处理异常事件
export function eventHandle (data) {
  const url = '/event/handle'
  return $axios.fGet(url, data)
}

// 会回退学生课时统计
export function delLessonHour (data) {
  const url = '/lesson/sale/del'
  return $axios.fGet(url, data)
}

// 获取统计周期列表
export function getListPeriod (data) {
  const url = '/stat/listPeriod'
  return $axios.fPost(url, data)
}

// 设置统计周期
export function setPeriod (data) {
  const url = '/stat/setPeriod'
  return $axios.fPost(url, data)
}

// 获取统计周期记录
export function getPeriod (data) {
  const url = '/stat/getPeriod'
  return $axios.fGet(url, data)
}

// 检查数据完整性（检查可否进行统计）
export function checkStat (data) {
  const url = '/stat/checkStat'
  return $axios.fGet(url, data)
}

// 更新排课信息快照
export function replaceSnapshot (data) {
  const url = '/stat/replaceSnapshot'
  return $axios.fGet(url, data)
}

// 同步周期内排课数据
export function replaceLessonInfo (data) {
  const url = '/stat/replaceLessonInfo'
  return $axios.fGet(url, data)
}

// 统计教师授课时长
export function statTeacherLessonDuration (data) {
  const url = '/stat/statTeacherLessonDuration'
  return $axios.fGet(url, data)
}

// 获取课时统计列表
export function getListTeacherLessonStat (data) {
  const url = '/stat/listTeacherLessonStat'
  return $axios.fPost(url, data)
}

// 获取课时详情列表
export function getListTeacherLesson (data) {
  const url = '/stat/listTeacherLesson'
  return $axios.fPost(url, data)
}

// 【教师】获取课时统计结果
export function getListMyLessonStat (data) {
  const url = '/stat/listMyLessonStat'
  return $axios.fPost(url, data)
}

// 【教师】获取课时详情列表
export function getListMyLesson (data) {
  const url = '/stat/listMyLesson'
  return $axios.fPost(url, data)
}

// 统计老师课酬
export function setStatTeacherSalary (data) {
  const url = '/stat/statTeacherSalary'
  return $axios.fPost(url, data)
}

// 获取课酬统计列表
export function getListTeacherLessonPayStat (data) {
  const url = '/stat/listTeacherLessonPayStat'
  return $axios.fPost(url, data)
}

//获取学员课消明细
export function getCancleProductInfo (data) {
  const url = '/product/user/getCancleProductInfo'
  return $axios.fPost(url, data)
}

//获取学员课消明细合计
export function getCancleProductTotal (data) {
  const url = '/product/user/getCancleProductTotal'
  return $axios.fPost(url, data)
}

// 教师获取课酬明细列表
export function getListSalaryMy (data) {
  const url = '/salary/listSalaryMy'
  return $axios.fPost(url, data)
}
